/**
 * 将服务端传过来的时间戳（毫秒）转为格式化的字符串
 * @param  {string|number} millisecondTimestamp 毫秒级时间戳
 * @return {string}  格式化时间
 */
export default function formatTime(millisecondTimestamp: string | number) {
  const ONE_MINUTE = 1000 * 60
  const ONE_HOUR = ONE_MINUTE * 60
  // var ONE_DAY = ONE_HOUR * 24;

  const now = new Date()
  const nowTimestamp = now.getTime()
  const fakeDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const d = new Date(Number(millisecondTimestamp))
  if (!Number(d.getTime())) {
    return ''
  }

  const delta = nowTimestamp - Number(millisecondTimestamp)

  if (delta < 0) {
    return ''
  } else if (delta < ONE_MINUTE) {
    return '刚刚'
  } else if (delta < ONE_HOUR) {
    return `${Math.floor(delta / ONE_MINUTE)}分钟前`
  } else if (delta < 24 * ONE_HOUR) {
    return `${Math.floor(delta / ONE_HOUR)}小时前`
  } else {
    const _hm = `${fixNumber(d.getHours())}:${fixNumber(d.getMinutes())}`
    let i = 0
    while (i++ <= 8) {
      fakeDate.setDate(fakeDate.getDate() - 1)
      if (millisecondTimestamp > fakeDate.getTime()) {
        if (i === 1) {
          return `昨天 ${_hm}`
        } else if (i === 2) {
          return `前天 ${_hm}`
        } else {
          return `${i}天前`
        }
      }
    }

    return `${(d.getFullYear() < now.getFullYear()
      ? `${d.getFullYear()}-`
      : '') + fixNumber(d.getMonth() + 1)}-${fixNumber(d.getDate())}`
  }
}

function fixNumber(num: number): string {
  if (num < 10) {
    return `0${num}`
  }
  return `${num}`
}
