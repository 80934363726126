import * as React from 'react'
import css from 'styled-jsx/css'
import classnames from 'classnames'
import optImage from 'lib/optimize-image'
import applyHttpsImage from 'lib/apply-https-img'
import formatDuration from 'lib/format-duration'
interface IProps {
  src: string
  duration?: number
  optImage?: boolean
  className?: string
  transition?: boolean
  loadAnimation?: boolean
  useBackground?: boolean
}

export default function Image(props: IProps) {
  const {
    optImage,
    src,
    className,
    transition,
    useBackground,
    duration,
  } = props
  const [newSrc, setSrc] = React.useState('')

  React.useEffect(() => {
    if (!src) {
      return
    }
    setSrc(optImage ? optImageFunc(src) : src)
  }, [optImage, src])

  const span = css.resolve`
    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      //background-color: #e6e6e6;
      background-image: url(${applyHttpsImage(newSrc)});
      transition: ${transition ? 'transform 0.3s ease-in-out' : 'none'};
    }

    span:hover {
      transform: ${transition ? 'scale(1.1)' : ''};
    }
  `

  const newProps = Object.assign(
    {},
    {
      src: newSrc,
      className: classnames(span.className, className),
    }
  )
  // return <span style={{backgroundImage: `url(${src})`}} className={className}></span>

  return (
    <React.Fragment>
      {useBackground ? (
        <span className={newProps.className}>
          {duration ? (
            <span className="video-btn">{formatDuration(duration)}</span>
          ) : null}
          <style jsx>
            {`
              .video-btn {
                display: block;
                position: absolute;
                bottom: 5px;
                right: 5px;
                font-size: 10px;
                color: hsla(0, 0%, 100%, 0.9);
                overflow: hidden;
                padding: 0px 6px 0px;
                border-radius: 15px;
                background-color: rgba(0, 0, 0, 0.4);
              }
              .video-btn::before {
                content: '';
                display: inline-block;
                background: url(//p.pstatp.com/origin/dc14000d8e436aea872a)
                  no-repeat center / cover;
                width: 10px;
                height: 10px;
                vertical-align: baseline;
                margin-right: 2px;
              }
            `}
          </style>
        </span>
      ) : (
        <img alt="" src={newProps.src} className={newProps.className} />
      )}
      {span.styles}
    </React.Fragment>
  )
}
function optImageFunc(src: string): string {
  if (typeof window === 'undefined') {
    return src
  }
  // 某些图片无法使用webp
  const blackList = [
    /image\/avatar/i,
    /list\/temai/i,
    /obj\/motor-img/i,
    /img\/mosaic-legacy/i,
    /img\/pgc-image/i,
    /img\/user-avatar/i,
  ]
  const matched = blackList.filter(reg => reg.test(src)).length !== 0
  if (matched) {
    return src
  }
  return applyHttpsImage(optImage(src))
}

Image.defaultProps = {
  optImage: true,
  transition: true,
  useBackground: true,
  loadAnimation: true,
}
