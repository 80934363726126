/* feed流/相关推荐的文章 */
import React from 'react'
import Link from 'next/link'

import formatTime from 'lib/format-time'
import getLinkHref from 'lib/get-link-href'
import formatDuration from 'lib/format-duration'

import Image from 'Components/image'

interface IProps {
  gid: string
  cover?: string
  isRecommend?: boolean
  duration?: number | string
  title: string
  source: string
  time: number
  type?: number
  index?: number
  isUGC?: boolean
  content?: string
  comment_count?: number
  onClick?(
    group_id: string | number,
    content_type?: number,
    index?: number
  ): void
  fromPage?: string
}

export default function Article(props: IProps) {
  const {
    fromPage = 'home',
    gid,
    cover,
    isRecommend,
    duration,
    title,
    source,
    time,
    type,
    index,
    isUGC,
    content,
    comment_count,
    onClick = () => {
      // 点击事件
    },
  } = props
  // let articleInfo: any
  /* data来自首页feed流 */
  // if (data.info) {
  //   const info = get(data, 'info', {})
  //   articleInfo = {
  //     title: info.title,
  //     source: info.source,
  //     time: info.cursor,
  //     duration: info.video_duration,
  //     isVideo: !!info.video_id,
  //     cover: get(data, 'info.image_list[0].url', '')
  //   }
  // } else {
  //   articleInfo = {
  //     title: data.title,
  //     source: get(data, 'author.name') || get(data, 'author_name'),
  //     time: data.create_time * 1000,
  //     duration: get(data, 'video.duration'),
  //     video_duration: get(data, 'video_duration'), // 已格式化的视频时间
  //     isVideo: !!data.video || data.video_duration,
  //     cover:
  //       get(data, 'image_list[0].url') ||
  //       get(data, 'video.cover_image_url') ||
  //       get(data, 'thumb_uri')
  //   }
  // }

  const href = `/${isUGC ? 'ugc/' : ''}article?gid=${gid}`
  const as: string = getLinkHref(`/${isUGC ? 'ugc/' : ''}article/${gid}`)
  // if (articleInfo.isVideo) {
  //   href = `//auto.365yg.com/motor/article/${data.unique_id_str}`
  //   as = ''
  // }
  return (
    <div
      className="box clearfix"
      onClick={() => {
        onClick(gid, type, index)
      }}
    >
      {cover ? (
        <Link href={href} as={as}>
          <a target="_blank" className="photo fl">
            <Image src={cover} />
            {duration ? (
              <span className="duration">
                <i />
                {typeof duration === 'string'
                  ? duration
                  : formatDuration(duration)}
              </span>
            ) : null}
          </a>
        </Link>
      ) : null}
      <div className="info">
        <Link href={href} as={as}>
          <a target="_blank" className="line-2 title">
            {title}
          </a>
        </Link>
        {content && (
          <Link href={href} as={as}>
            <a target="_blank" className="line-1 content">
              {content}
            </a>
          </Link>
        )}
        <Link href={href} as={as}>
          <a target="_blank" className="name">
            <span className="type">{duration ? '视频' : '文章'}</span>
            <span>{source}</span>
            <span>{formatTime(time)}</span>
          </a>
        </Link>
      </div>
      <style jsx>
        {`
          .box {
            min-height: 124px;
            border-bottom: 1px solid #ededed;
            padding: ${fromPage === 'feed' ? '16px' : '12px'} 0;
          }
          .photo {
            position: relative;
            display: block;
            margin-right: 16px;
            width: 150px;
            height: 100px;
            overflow: hidden;
            cursor: pointer;
          }
          .duration {
            position: absolute;
            right: 4px;
            bottom: 4px;
            display: block;
            width: 94px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            text-align: center;
            border-radius: 30px;
            color: #fff;
            transform: scale(0.5);
            transform-origin: right bottom;
            background-color: rgba(0, 0, 0, 0.4);
          }
          .duration i {
            display: inline-block;
            width: 17px;
            height: 29px;
            margin-right: 3px;
            vertical-align: text-bottom;
            background: url(${require('static/palySmallWhiteCopy11@3x.png')})
              no-repeat center / 17px 17px;
          }
          .info {
            position: relative;
            display: table-cell;
            width: 100%;
            height: 100px;
            vertical-align: middle;
            text-align: left;
          }
          .title {
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            color: #1f2129;
          }
          .title:hover {
            color: #e65800;
          }
          .title:visited {
            color: #999;
          }
          .name {
            display: flex;
            align-items: center;
            margin-top: 12px;
            color: #969aa9;
          }
          .name span {
            margin-right: 8px;
          }
          .content {
            font-size: 14px;
            line-height: 22px;
            color: #606370;
            margin-top: 8px;

            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-all;
            overflow: hidden;
            white-space: normal;
          }

          .type {
            border-radius: 2px;
            color: #979aa8;
            font-size: 16px;
            padding: 1px 4px;
            border: solid 1px #c9cbd6;
            transform: scale(0.75);
            display: inline-block;
            transform-origin: center center;
          }

          .photo.fl {
            border-radius: 2px;
          }
        `}
      </style>
    </div>
  )
}
