/**
 * 将服务端传过来的时长（秒）格式化为时分秒
 * @param  {number} 秒级
 * @return {string}  格式化为时分秒
 */
export default function formatDuration(second: number) {
  if (second < 3600) {
    return [Math.floor((second / 60) % 60), Math.floor(second % 60)]
      .join(':')
      .replace(/\b(\d)\b/g, '0$1')
  } else {
    return [
      Math.floor(second / 60 / 60),
      Math.floor((second / 60) % 60),
      Math.floor(second % 60),
    ]
      .join(':')
      .replace(/\b(\d)\b/g, '0$1')
  }
}
