import canUseWebp from './can-use-webp'

const defaultOrigin = 'pstatp.com'
/**
 * 图片优化服务，优先使用webp
 * @param src 图片地址
 * @returns 优化后的地址
 */
function optimizeImage(src: string, whiteList?: string[]): string {
  if (!src) return ''
  const origins = (whiteList || []).concat(defaultOrigin)
  if (!canUseWebp) {
    return src
  }
  const image = src
  const finded = origins.find(item => new RegExp(item, 'i').test(item))
  if (!finded) {
    return src
  }
  const reg = /(\.png|\.jpg|\.jpeg|\.webp)$/
  return reg.test(image)
    ? image.replace(/\.png|\.jpg|\.jpeg|\.webp/, '.webp')
    : `${image}.webp`
}

export const processUrlWith_tplv_noop = (uri = '', size = 350): string => {
  let uri_handled = uri

  if (uri_handled.includes('large')) {
    uri_handled = uri_handled.replace('large/', '')
  }

  const random = Math.random()
  const host = `https://sf${
    random > 0.67 ? 1 : random > 0.33 ? 3 : 6
  }-ttcdn-tos.pstatp.com`
  return `${host}/img/${uri_handled}~${size}x0.png`
}

export default optimizeImage
